<template>
  <section id="home" ref="bgEffect" class="flexbox-center">
    <transition name="fade" appear>
      <div class="container">
        <div id="home_icons">
          <router-link to="/a-propos">
            <font-awesome-icon icon="fa-solid fa-user-alt" />
          </router-link>
          <router-link to="/portfolio">
            <font-awesome-icon icon="fa-solid fa-book-open" />
          </router-link>
          <router-link to="/contact">
            <font-awesome-icon icon="fa-solid fa-hands-helping" />
          </router-link>
        </div>
        <h1 ref="shadowEffect">Baptiste Ory</h1>
        <p id="copyright">© 2022</p>
        <p id="entitled">Ingénieur multimédia</p>
      </div>
    </transition>
  </section>
</template>

<script>

  export default {
    name: 'Home',
    mounted () {
      // this.$parent.createBgEffect(this.$refs.bgEffect)
      setTimeout(() => { this.$refs.shadowEffect.className += ' shadow' }, 100)
    }
  }

</script>

<style scoped lang="scss">

  #home {
    padding: 0 12px;
  }

  #home_icons {
    font-size: 2em;
    position: relative;
    right: 10px;

    a {
      color: #FFFFFF;
      margin-right: 1em;

      &:hover {
        color: #00917e;
        cursor: pointer;
        transition: color .2s;
      }
    }
  }

  h1 {
    font-size: 8em;
    text-align: left;
  }

  #entitled {
    color: white;
    font-size: 2em;
    margin: 25px 0 40px 0;
    text-align: right;
    width: 100%;

    &::before {
      content: '';
      background-color: white;
      display: block;
      margin-left: 60%;
      position: relative;
      top: -16px;
      right: 0;
      height: 4px;
      width: 40%;
    }
  }

  #copyright {
    color: white;
    font-size: 1.2em;
    margin: -3px 0 0 25px;
    text-align: left;
    width: 100%;
  }

  #home_icons,
  h1,
  #copyright,
  #entitled {
    transform: rotate(-12deg);
  }

  .fade-enter-active, .fade-leave-active {
    transition-delay: .2s;
  }

/*------------------------------
Responsiveness
------------------------------*/

  @media only screen and (max-width: 1140px) {
    h1 {
      font-size: 5em;

      &.shadow {
        text-shadow: #f3fffd 2px 0px 0px, #f3fffd 1.75517px 0.958851px 0px, #f3fffd 1.0806px 1.68294px 0px, #f3fffd 0.141474px 1.99499px 0px, #f3fffd -0.832294px 1.81859px 0px, #f3fffd -1.60229px 1.19694px 0px, #f3fffd -1.97998px 0.28224px 0px, #f3fffd -1.87291px -0.701566px 0px, #f3fffd -1.30729px -1.5136px 0px, #f3fffd -0.421592px -1.95506px 0px, #f3fffd 0.567324px -1.91785px 0px, #f3fffd 1.41734px -1.41108px 0px, #f3fffd 1.92034px -0.558831px 0px,
        0 0 #f3fffd, 2px 2px 0 #f3fffd, 3px 3px 0 #f3fffd, 4px 4px 0 #f3fffd, 5px 5px 0 #f3fffd, 6px 6px 0 #f3fffd, 7px 7px 0 #f3fffd, 8px 8px 0 #f3fffd, 9px 9px 0 #f3fffd, 10px 10px 0 #f3fffd, 11px 11px 0 #f3fffd, 12px 12px 0 #f3fffd, 0 0 rgba(33, 33, 33, 0.5), 15px 15px 0 rgba(33, 33, 33, 0.5), 16px 16px 0 rgba(33, 33, 33, 0.5), 17px 17px 0 rgba(33, 33, 33, 0.5), 18px 18px 0 rgba(33, 33, 33, 0.5), 19px 19px 0 rgba(33, 33, 33, 0.5), 20px 20px 0 rgba(33, 33, 33, 0.5);
      }
    }
  }

  @media only screen and (max-width: 720px) {
    #home_icons {
      bottom: 10px;

      a {
        margin-right: .5em;
      }
    }

    h1 {
      font-size: 3.5em;

      &.shadow {
        text-shadow: #f3fffd 1px 0px 0px, #f3fffd 0.540302px 0.841471px 0px, #f3fffd -0.416147px 0.909297px 0px, #f3fffd -0.989992px 0.14112px 0px, #f3fffd -0.653644px -0.756802px 0px, #f3fffd 0.283662px -0.958924px 0px, #f3fffd 0.96017px -0.279415px 0px,
        0 0 #f3fffd, 1.5px 1.5px 0 #f3fffd, 2.5px 2.5px 0 #f3fffd, 3.5px 3.5px 0 #f3fffd, 4.5px 4.5px 0 #f3fffd, 0 0 rgba(33, 33, 33, 0.5), 4.5px 4.5px 0 rgba(33, 33, 33, 0.5), 5.5px 5.5px 0 rgba(33, 33, 33, 0.5), 6.5px 6.5px 0 rgba(33, 33, 33, 0.5);
      }
    }

    #copyright {
      font-size: 0.8em;
      margin: -1px 0 0 18px;
    }
  }

  @media only screen and (max-width: 540px) {
    #home_icons {
      font-size: 1.5em;
    }

    h1 {
      font-size: 2.5em;

      &.shadow {
        text-shadow: #f3fffd 1px 0px 0px, #f3fffd 0.540302px 0.841471px 0px, #f3fffd -0.416147px 0.909297px 0px, #f3fffd -0.989992px 0.14112px 0px, #f3fffd -0.653644px -0.756802px 0px, #f3fffd 0.283662px -0.958924px 0px, #f3fffd 0.96017px -0.279415px 0px,
        0 0 #f3fffd, 1.5px 1.5px 0 #f3fffd, 2.5px 2.5px 0 #f3fffd, 3.5px 3.5px 0 #f3fffd, 4.5px 4.5px 0 #f3fffd, 0 0 rgba(33, 33, 33, 0.5), 4.5px 4.5px 0 rgba(33, 33, 33, 0.5), 5.5px 5.5px 0 rgba(33, 33, 33, 0.5), 6.5px 6.5px 0 rgba(33, 33, 33, 0.5);
      }
    }

    #entitled {
      font-size: 1.5em;
    }
  }

</style>
