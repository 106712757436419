<template>
  <section id="about">
    <simplebar class="simplebar" data-simplebar-auto-hide="false">
      <div class="container">
        <h1 ref="shadowEffect">À propos</h1>
        <div class="row flexbox-center" id="description">
          <div class="col-lg-3 text-center contImg">
            <transition name="fade" appear>
              <img src="@/assets/photo_baptisteOry.jpg" alt="Photo de Baptiste Ory">
            </transition>
          </div>
          <div class="col-lg-9">
            <p>
              Je suis diplômé de l'école d'ingénieur <a href="https://www.ingenieur-imac.fr/" class="link" target="_blank">IMAC</a> (Image, Multimédia, Audiovisuel et Communication) et d'un
              <a href="https://iutmmi.fr/" class="link" target="_blank">DUT Métiers du Multimédia et de l’Internet (MMI)</a>, une formation m'ayant apporté de nombreuses
              connaissances et une grand polyvalence dans le domaine du multimédia (intégration, programmation, graphisme, vidéo, etc.). Je travaille actuellement à CANAL+,
              en tant que chargé d'intégration multimédia. Également, je suis fortement intéressé par les liens entre l'art et les nouveaux outils numériques.
            </p>
          </div>
        </div>
        <div class="row" id="skills">
          <div class="col-lg-4">
            <font-awesome-icon icon="fa-solid fa-file-code" />
            <span class="skill_title">Prog. et intégration</span>
            <span>HTML, CSS, Javascript, PHP, SQL<br />C / C++ / OpenGL, Java, VSCode<br />Bootstrap, JQuery,
              Three.js, p5.js<br />Vue.js, HyperApp, Wordpress, WebGL</span>
          </div>
          <div class="col-lg-4">
            <font-awesome-icon icon="fa-solid fa-paint-brush" />
            <span class="skill_title">Design, jeux et 3D</span>
            <span>Unity, Blender, Adobe Photoshop, Adobe Illustrator, Adobe InDesign, Figma</span>
          </div>
          <div class="col-lg-4">
            <font-awesome-icon icon="fa-solid fa-video" />
            <span class="skill_title">Audiovisuel</span>
            <span>Adobe Premiere Pro, Adobe After Effects, Adobe Audition, Pro Tools, MAX/MSP<br /></span>
            <span>Viz Artist – Viz Wizard – Viz Arc – Viz Multiplay</span>
          </div>
        </div>
        <div class="row" id="cv">
          <div class="col-lg-3 offset-lg-3 text-center">
            <a href="/content/CVGraphique_BaptisteORY.pdf" target="_blank" class="btn btn-submit">Mon CV graphique</a>
          </div>
          <div class="col-lg-3 text-center">
            <a href="/content/CVImprimable_BaptisteORY.pdf" target="_blank" class="btn btn-submit">Mon CV imprimable</a>
          </div>
        </div>
      </div>
    </simplebar>
  </section>
</template>

<script>

  export default {
    name: 'About',
    mounted () {
      this.$refs.shadowEffect.className += ' shadow'
    }
  }

</script>

<style scoped lang="scss">

  #description {
    img {
      border-radius: 50%;
      border: 4px solid #FFFFFF;
      width: 100%;
      margin: 25px 0;
      max-width: 250px;
      min-height: 250px;
    }

    .link {
      position: relative;
      font-weight: 600;
      color: #FFFFFF;

      &:hover {
        color: #00917e;
      }
    }
  }

  #skills {
    margin: 40px 0;
    text-align: center;

    svg {
      font-size: 2em;
    }

    .skill_title {
      background-color: white;
      border-radius: 5px;
      display: block;
      color: #000000;
      font-size: 1.3em;
      font-weight: 600;
      line-height: 1.2;
      margin: 15px 0;
      padding: 3px 10px;
    }
  }

/*------------------------------
Responsiveness
------------------------------*/

  @media only screen and (max-width: 1140px) {
    #description img {
      min-height: 210px;
    }
  }

  @media only screen and (max-width: 960px) {
    #skills>div:not(:first-child) {
      margin-top: 25px;
    }
  }

  @media only screen and (max-width: 540px) {
    #description img {
      max-width: 200px;
      max-height: 200px;
      min-height: auto;
    }
  }

</style>
